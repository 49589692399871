import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/ministries.css"


function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/about-us/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        },
	        site {
	        	siteMetadata{
				    aboutUsMetadata {
				      links {
				        link
				        url
				      }
				      sidebarTextblocks {
				        body
				        header
				      }
				      subheader {
				        main
				        sub
				      }
				    }
				}
			  }
			}
	`


	const pageBlockDatas = [

		]

	const pageBlocks = pageBlockDatas.map(
    (pageBlockData, key) => 
      <PageBlock key={key} childClass="eri" element={pageBlockData.element} header={pageBlockData.header} />
    )	

	let data = useStaticQuery(query)

	console.log(data)

	let images = data.allFile
	let sidebarData = data.site.siteMetadata.aboutUsMetadata.sidebarTextblocks;
	let links = data.site.siteMetadata.aboutUsMetadata.links;
	let subheaderData = data.site.siteMetadata.aboutUsMetadata.subheader; 

	
	const sidebarImage = images.edges.find(element => element.node.name === 'sidebar').node
	const underConstruction = images.edges.find(element => element.node.name === 'under-construction').node
	const location = ""
	

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content ministries-colors ministries">
				<div>
					<Subheader data={subheaderData} />
				</div>
				<div className="under-construction-wrapper">
				<div className="under-construction">
						<Img
		           		className="under-construction-img"
		           		fluid={underConstruction.childImageSharp.fluid}
		               	alt="" />
		        </div>
		        </div>
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 